import { generatedPositiveYesDetailTypes, generatedEntryRestrictionDetailChildrenDetails, generatedEntryRestrictionDetailTypes, generatedEntryRestrictionDetailTypesGenerated } from '@/generated/store/details'

export default {

  getDetailTypeByKey(key) {
    var found = generatedEntryRestrictionDetailTypes[key]
    if (!found) {
      found = generatedEntryRestrictionDetailTypesGenerated[key]
    }
    return found
  },

  isYesIsPositiveDetailType(detailType) {
    return generatedPositiveYesDetailTypes.includes(detailType)
  },

  getDetailByTypeFromGroups(detailType, grouped) {
    for (let group of grouped) {
      for (let detail of group.details) {
        if (detailType == detail.detailType) {
          return detail
        }
      }
    }
    return null
  },

  getDetailsByTypesFromGroups(detailTypes, grouped) {
    let details = []
    for (let group of grouped) {
      let found = group.details.filter(detail => detailTypes.indexOf(detail.detailType) !== -1)
      details.push(...found)
    }
    return details
  },

  getDetailsByTypes(detailTypes, details) {
    let result = []
    for (let detailType of detailTypes) {
      let rs = details.find(detail => detail.detailType == detailType)
      if (rs) {
        result.push(rs)
      }
    }
    return result
  },

  /**
   * Returns true if Yes or a Ok/Yes sign should be shown for this status
   */
  isDetailStatusIsYes(detail) {
    if (detail === null) {
      return false
    }

    if (detail.status == 'NO') {
      return false
    }

    if (detail.status == 'BLUE') {
      return false
    }

    // default
    return true
  },

  getDetailColor(detail) {
    if (detail === null) {
      return 'red'
    }

    if (detail.status == 'YES') {
      if (this.isYesIsPositiveDetailType(detail.detailType)) {
        return 'green'
      } else {
        return 'red'
      }
    }
    if (detail.status == 'NO') {
      if (this.isYesIsPositiveDetailType(detail.detailType)) {
        return 'red'
      } else {
        return 'green'
      }
    }

    if (detail.status == 'BLUE') {
      return 'green'
    }
    if (detail.status == 'RED') {
      return 'red'
    }
    if (detail.status == 'YELLOW') {
      return 'red'
    }

    // default
    return 'black'
  },

  /**
   * Remove all details that are not usable in a business view
   */
  filterGroupedDetailsForBusiness(groups) {
    const dfilter = generatedEntryRestrictionDetailChildrenDetails
    let result = []

    for (let group of groups) {
      let newGroup = JSON.parse(JSON.stringify(group)) // deepcopy
      newGroup.details = newGroup.details.filter(detail => !dfilter.includes(detail.detailType))

      if (newGroup.details.length > 0) {
        result.push(newGroup)
      }
    }

    return result
  }

}


