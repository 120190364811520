<template>
  <div class="tabbed-content">
    <v-tabs
      v-model="contentTab"
      class="flex-grow-0 gm-tabs"
    >
      <v-tab>{{ $t('main.OVERVIEW') }}</v-tab>

      <v-tab v-for="contentTab in contentTabs" :key="contentTab">
        {{ contentTab }}
      </v-tab>

      <v-tab
        v-if="$hasPermission('a3m_core.DestinationManagerCDB')"
      >
        {{ $t('main.COUNTRY_INFORMATION_TAB') }}
      </v-tab>
      <v-tab>{{ $t('main.GMEVENTS') }}</v-tab>
    </v-tabs>

    <v-tabs-items
      v-model="contentTab"
      class="flex-grow-1 gm-tab-items"
    >
      <v-tab-item class="pa-4">
        <DestinationContentPreview
          :items="items"
          :content="content"
          :countryRiskCategories="countryRiskCategories"
          :events="events"
          :destinationCountry="destinationCountry"
          :transitCountry="transitCountry"          
        />
      </v-tab-item>
      <v-tab-item
        v-for="contentMainCategory in contentTabItems"
        :key="contentMainCategory.key"
        class="px-4 content-tab"
      >
        <AcceptableAlert
          v-if="getDisclaimerAcceptTextByMainCategory(contentMainCategory.key)"
          :accept-key="'maincategory--' + contentMainCategory.key"
          :action-text="$t('main.UNDERSTOOD_ACTION')"
          outlined
          type="info"
          class="accept-disclaimer mt-4"
        >
          {{ getDisclaimerAcceptTextByMainCategory(contentMainCategory.key) }}
        </AcceptableAlert>

        <EntryRestrictionDetailGroupList
          :groups="contentMainCategory.content"
          :openAll="openAll"
          :breakPoints="getBreakPointsByMainCategory(contentMainCategory.key)"
          :disclaimer="getDisclaimerTextByMainCategory(contentMainCategory.key)"

          class="content-list"
        />

        <div class="pb-4">
          <v-btn text color="grey" @click="openAll=openAll+1">{{ $t('main.OPEN_ALL_ACTION') }}</v-btn>
        </div>
      </v-tab-item>

      <v-tab-item
        v-if="$hasPermission('a3m_core.DestinationManagerCDB')"
        class="country-information-tab"
      >
        <CountryInformation
          :countryIso2="countryIso2"
          class="country-information-iframe"
        />
      </v-tab-item>
      <v-tab-item class="event-display-tab">
        <EventDisplay
          :events="events"
          class="event-display-list"
        />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { generatedEntryRestrictionDetailDisplayMainCategories } from '@/generated/store/details';
import EntryRestrictionDetailGroupList from '@/components/entryrestriction/EntryRestrictionDetailGroupList'
import DestinationContentPreview from '@/components/destination/DestinationContentPreview';
import CountryInformation from '@/components/countryinformation/CountryInformation'
import EventDisplay from '@/components/event/EventDisplay'
import AcceptableAlert from '@/components/ui/AcceptableAlert'

export default {
  name: "DestinationTabbedContent",
  components: {
    EntryRestrictionDetailGroupList,
    DestinationContentPreview,
    CountryInformation,
    EventDisplay,
    AcceptableAlert
  },
  props: {
    items: {
      type: Array,
      required: true
    },    
    content: {
      type: Array,
      required: true
    },
    countryRiskCategories: {
      type: Array,
      required: true
    },
    events: {
      type: Array,
      required: true
    },
    countryIso2: {
      type: String,
      required: true
    },
    destinationCountry: {
      type: Object,
      required: true
    },
    transitCountry: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      contentTab: 0,
      openAll: 0,
      breakPointsByMainCategory: {
        entry_requirements: [
          'er_required_travel_documents',
          'er_documents_for_transit'
        ],        
        entry_covid19: [
          'entry_for_vaccinated',
          'entry_for_recovered'
        ],
        conditions_on_site_covid19: [
          'conditions_on_site_activities',
          'conditions_on_site_hoteltransport'
        ],
        return_trip_covid19: [
          'reentry_for_vaccinated',
          'reentry_for_recovered'
        ],

      },
      disclaimerByMainCategory: {
        /*
        entry_requirements: {
          // this main category is german-only, so no translations here, too
          disclaimer: this.$t('main.ENTRY_REQUIREMENTS_TAB_DISCLAIMER'),
          acceptDisclaimer: this.$t('main.ENTRY_REQUIREMENTS_TAB_DISCLAIMER_ACCEPT'),
        },
        entry_covid19: {
          disclaimer: this.$t('main.ENTRY_COVID19_TAB_DISCLAIMER'),
          acceptDisclaimer: this.$t('main.ENTRY_COVID19_TAB_DISCLAIMER_ACCEPT'),
        }
        */
      }
    }
  },
  computed: {
    contentMainCategories() {
      let mainCategories = []
      for (let group of this.content) {
        if (mainCategories.indexOf(group.mainCategory) === -1) {
          mainCategories.push(group.mainCategory)
        }
      }

      return mainCategories
    },
    contentTabs() {
      let tabs = []
      for (let mainCategory of this.contentMainCategories) {
        tabs.push(this.getMainCategoryName(mainCategory))
      }
      return tabs
    },
    contentTabItems() {
      let mainCategoryTabItems = []
      for (let mainCategory of this.contentMainCategories) {
        mainCategoryTabItems.push({
          key: mainCategory, 
          content: this.content.filter(group => group.mainCategory == mainCategory)
        })
      }
      return mainCategoryTabItems
    }
  },
  methods: {
    getBreakPointsByMainCategory(mainCategory) {
      if (!this.breakPointsByMainCategory[mainCategory]) {
        return []
      }
      return this.breakPointsByMainCategory[mainCategory]
    },
    getDisclaimerByMainCategory(mainCategory) {
      if (!this.disclaimerByMainCategory[mainCategory]) {
        return null
      }
      return this.disclaimerByMainCategory[mainCategory]
    },
    getDisclaimerTextByMainCategory(mainCategory) {
      let disclaimer = this.getDisclaimerByMainCategory(mainCategory)

      if (disclaimer !== null && disclaimer['disclaimer']) {
        return disclaimer['disclaimer']
      }
    },
    getDisclaimerAcceptTextByMainCategory(mainCategory) {
      let disclaimer = this.getDisclaimerByMainCategory(mainCategory)

      if (disclaimer !== null && disclaimer['acceptDisclaimer']) {
        return disclaimer['acceptDisclaimer']
      }
    },
    getMainCategoryName(mainCategory, language) {
      if (mainCategory == '') {
        return '-'
      }

      return generatedEntryRestrictionDetailDisplayMainCategories[mainCategory].name
    },
  }
}
</script>

<style scoped>
  .tabbed-content {
    display: flex;
    flex-direction: column;
  }

  .country-information-tab {
    height: 100%;
  }

  .country-information-iframe {
    height: 100%;
    width: 100%;
  }

  .event-display-tab {
    height: 100%;
    display: flex;
  }

  .content-tab {
    height: 100%;
    display: flex;
    flex-direction: column;
  }


  .content-list {
    height: 100%;
  }

</style>