<template>
  <div class="entry-restriction-list">

    <h2 class="subtitle-1 mt-5">{{ name }}</h2>

    <!--
    <div>
      <v-btn color="grey" text @click="openAll" class="pl-0">Alle öffnen</v-btn>
      <v-btn color="grey" text @click="closeAll">Alle schließen</v-btn>
    </div>
    -->

    <v-expansion-panels
      v-model="panel"
      multiple
      focusable
    >
      <template v-for="detail in details">
        <!-- We only show details that have a status or a text. Removes empty "Others" boxes -->

        <v-expansion-panel
          v-if="detail.status != '' || detail.note != ''"
          :key="detail.detailType"
        >
          <v-expansion-panel-header
            :disable-icon-rotate="detail.status !== ''"
          >
            {{ translateTitle(detail.detailType, detail.name, detail.status) }}

            <v-spacer />

            <template v-slot:actions>
              <!-- YES means: Quarantine, so we show a warning sign -->
              <v-icon
                v-if="checkFlipStatus(detail.detailType, detail.status) == 'YES' || detail.status == 'RED'"
                color="red"
              >
                warning
              </v-icon>

              <!-- Unknown is dangerous, too -->
              <v-icon
                v-else-if="detail.status == 'YELLOW'"
                color="orange"
              >
                warning
              </v-icon>

              <!-- ALL IS FINE :) -->
              <v-icon
                v-else-if="detail.status == 'BLUE' || detail.status == 'GREEN' || checkFlipStatus(detail.detailType, detail.status) == 'NO'"
                color="teal"
              >
                done
              </v-icon>

              <!-- UNKNOWN -->
              <v-icon
                v-else-if="detail.status == 'GRAY' || detail.status == 'UNKNOWN' || (detail.status == '' && !isFreeTextDetailType(detail.detailType) )"
                color="gray"
              >
                help_outline
              </v-icon>

              <!-- FALLBACK -->
              <v-icon
                v-else
              >
                $expand
              </v-icon>
            </template>

          </v-expansion-panel-header>
          <v-expansion-panel-content
            eager
          >
            <EntryRestrictionDetailBox
              class="pt-6"
              :status="detail.status"
              :text="detail.note"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </template>
    </v-expansion-panels>
  </div>
</template>

<script>
import EntryRestrictionService from '@/services/EntryRestrictionService';
import EntryRestrictionDetailBox from './EntryRestrictionDetailBox'
import { generatedNoStatusDetailTypes } from '@/generated/store/details'

export default {
  name: 'EntryRestrictionDetailList',
  components: {
    EntryRestrictionDetailBox
  },
  props: {
    name: {
      type: String,
      required: true
    },
    details: {
      type: Array,
      required: true
    },
    open: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      panel: []
    }
  },
  methods: {
    openAll() {
      this.panel = [...Array(this.details.length).keys()]
    },
    closeAll() {
      this.panel = []
    },

    /**
     * YES -> NO
     * NO -> YES
     */
    flipStatus(status) {
      if (status === 'YES') {
        return 'NO'
      }
      if (status === 'NO') {
        return 'YES'
      }
      return status
    },

    checkFlipStatus(detailType, status) {
      if (EntryRestrictionService.isYesIsPositiveDetailType(detailType)) {
        return this.flipStatus(status)
      }

      return status
    },

    /**
     * TODO: Move to a service!!!
     * or better: Create a detail type "type-list", like in the manager-frontend
     */
    isFreeTextDetailType(detailType) {
      return generatedNoStatusDetailTypes.includes(detailType)
    },
    translateTitle(detailType, title, status) {
      if (status === 'YES') {
        title = title + ': ' + this.$t('main.YES')
      }
      if (status === 'NO') {
        title = title + ': ' + this.$t('main.NO')
      }
      if (status === 'UNKNOWN') {
        title = title + ': ' + this.$t('main.UNKNOWN')
      }

      if (!this.isFreeTextDetailType(detailType) && status === '') {
        title = title + ': ' + this.$t('main.UNKNOWN')
      }

      return title
    }
  },
  watch: {
    open() {
      if (this.open) {
        this.openAll()
      } else {
        this.closeAll()
      }
    }
  }
}
</script>

<style scoped>
  .entry-restriction-list .entry-restriction hr {
    /*border-bottom: 1px solid #eaeaea;
    margin-bottom: 1rem;
    */
    max-width: 50px;
    border: none;
    border-top: 1px solid #eaeaea;
    margin-bottom: 5px;
  }

  .entry-restriction-list .entry-restriction:last-child hr {
    /*border-bottom: none;*/
    display: none
  }

</style>
