<template>
  <div class="bg">
    <div class="message">
      <v-row>
        <v-col cols="auto">
          <v-icon size="200" color="grey lighten-1">search</v-icon>
        </v-col>
        <v-col>
          <h1 class="headline">{{ $t('main.DESTINATION_INTRO_TITLE') }}</h1>
          <p class="pt-2">{{ $t('main.DESTINATION_INTRO_TEXT_1') }}</p>
          <p class="mt-6 pt-6 caption">{{ $t('main.DESTINATION_INTRO_TEXT_2') }}</p>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "DestinationEmptyView",
}
</script>

<style scoped>
  .bg {
    height: 100%;
    background: url("../../assets/network.svg"), linear-gradient(180deg, rgba(235,234,234, 0.2) 0%, rgba(195,195,195, 1) 100%);
    background-color: #ebeaea;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    padding-top: 5rem;
    flex: 1;
  }

  .message {
    margin: auto;
    width: 600px;
    color: gray;
  }
</style>