<template>
  <v-row>
    <v-col cols="auto">
      <v-icon class="mt-5" color="primary">search</v-icon>
    </v-col>
    <v-col>
      <DestinationLookup
        v-model="start"
        :error-messages="startErrorMessages"
        :label="$t('main.SEARCH_BAR_DEPARTURE')"
      />
    </v-col>    
    <v-col>
      <DestinationLookup
        v-model="destination"
        :error-messages="destinationErrorMessages"
        :label="$t('main.SEARCH_BAR_DESTINATION')"
      />
    </v-col>
    <v-col>
      <DestinationLookup
        v-model="transit"
        :label="$t('main.SEARCH_BAR_TRANSIT')"
      />
    </v-col>
    <v-col cols="1">
      <DatePicker
        v-model="dateStart"
        :max="maxDate"
        :label="$t('main.SEARCH_BAR_TRAVEL_DATE_START')"
      />
    </v-col>
    <v-col cols="1">
      <DatePicker
        v-model="dateEnd"
        :min="dateStart"
        :max="maxDate"
        :label="$t('main.SEARCH_BAR_TRAVEL_DATE_END')"
      />
    </v-col>
    <v-col>
      <CountryLookup
        v-model="nationality"
        :error-messages="nationalityErrorMessages"
        :label="$t('main.SEARCH_BAR_CITIZENSHIP')"
      />
    </v-col>
    <v-col cols="1">
      <v-select
        v-model="restrictionType"
        :items="restrictionTypes"
        :label="$t('main.SEARCH_BAR_TRAVELTYPE')"
      />
    </v-col>
    <v-col cols="auto" class="pt-6">
      <v-btn color="primary" @click="search">{{ $t('main.SEARCH_ACTION') }}</v-btn>
    </v-col>
  </v-row>
</template>


<script>
import moment from "moment"
import DestinationLookup from '@/components/destination/DestinationLookup'
import CountryLookup from '@/components/destination/CountryLookup'
import DatePicker from '@/components/ui/DatePicker'

export default {
  name: "DestinationSearchBar",
  components: {
    DestinationLookup,
    CountryLookup,
    DatePicker
  },
  data() {
    return {
      start: null,
      destination: null,
      transit: null,
      nationality: null,
      maxDate: moment().add(3, 'years').format("YYYY-MM-DD"),
      dateStart: moment().format("YYYY-MM-DD"),
      dateEnd: moment().add(2, 'weeks').format("YYYY-MM-DD"),
      restrictionTypes: [
        {'value': 'TOURISM', 'text': this.$i18n.t('main.PRIVATE_TRAVEL')},
        {'value': 'BUSINESS', 'text': this.$i18n.t('main.BUSINESS_TRAVEL')},
      ],
      startErrorMessages: null,
      destinationErrorMessages: null,
      nationalityErrorMessages: null
    }
  },
  computed: {
    restrictionType: {
      get() {
        return this.$store.state.uiStore.restrictionType
      },
      set(value) {
        return this.$store.commit("uiStore/SET_RESTRICTIONTTYPE", value)
      }
    }
  },
  methods: {
    search() {
      var hasErrors = false
      if (this.start === null) {
        hasErrors = true
        this.startErrorMessages = [
          "Required"
        ]
      }
      if (this.destination === null) {
        hasErrors = true
        this.destinationErrorMessages = [
          "Required"
        ]
      }
      if (this.nationality === null) {
        hasErrors = true
        this.nationalityErrorMessages = [
          "Required"
        ]
      }

      if (!hasErrors) {
        var transitId = "0";
        var transitType = "UNDEFINED";
        if (this.transit) {
          transitId = this.transit.id;
          transitType = this.transit.destinationType;
        }

        this.$emit('search', {
          departureId: this.start.id,
          departureType: this.start.destinationType,
          destinationId: this.destination.id,
          destinationType: this.destination.destinationType,
          transitId: transitId,
          transitType: transitType,
          citizenshipCountryId: this.nationality.id,
          dateStart: this.dateStart,
          dateEnd: this.dateEnd,
          restrictionType: this.restrictionType
        })
      }
    }
  }
}
</script>