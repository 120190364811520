<template>
  <div>
    <img src="@/assets/logo_visagate.png" class="logo" />
    <v-btn class="mt-4 ml-2" color="primary" small @click="openDvkg">{{ $t('main.VISA_REQUIREMENTS') }}</v-btn>
  </div>
</template>

<script>

export default {
  name: "VisaGateBox",
  props: {
    iso2: String
  },
  data() {
    return {
       endPoint: "https://widget.visa-gate.com/"
    };
  },
  methods: {
    openDvkg() {
      let url = `${this.endPoint}?&country=${this.iso2}`;
      window.open(url, '_blank')
    }
  }
};
</script>

<style scoped>
.main-row {
  margin-top: 2rem;
}

.logo {
  width: 100%;
}

</style>