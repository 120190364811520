<template>
  <v-autocomplete
    ref="autocomplete"
    :value="selectedDestinations"
    @input="updateValue($event)"
    :search-input.sync="searchText"
    :loading="isLoading"
    :items="destinations"
    :error-messages="localErrorMessages"
    item-text="name"
    item-value="key"
    :label="label"
    :no-data-text="$t('main.NO_RESULTS_FOUND')"
    :menu-props="{closeOnContentClick:true}"
    cache-items
    hide-details
    hide-selected
    auto-select-first
    open-on-clear
    return-object
    multiple
    chips
    dense
  >
    <template v-slot:selection="data">
      <v-chip
        v-bind="data.attrs"
        :input-value="data.selected"
        close
        @click="data.select"
        @click:close="remove(data.item)"
      >
        {{ shortenName(data.item.name) }}
      </v-chip>
    </template>    
  </v-autocomplete>
</template>

<script>
import { debounce } from "debounce"
import moment from "moment"
import { mapState, mapActions } from "vuex";

export default {
  name: "DestinationLookupIdMultiSelect",
  created() {
    /* must be in a per-component base, or we get problems with recreated instances*/
    this.debounceQueryLookupDestination = debounce((stext) => {
      return this.queryLookupDestination(stext)
    }, 500)
  },
  mounted() {
    this.isLoading = false // TODO
    this.localErrorMessages = this.errorMessages
  },
  props: {
    value: [],
    label: {
      type: String,
      default: ""
    },
    errorMessages: null
  },
  data() {
    return {
      destinations: [], // TODO TODO TODO TODO TODO TODO TODO TODO TODO
      selectedDestinations: [],
      searchText: "",
      lookupResult: [],
      isLoading: false,
      localErrorMessages: null
    }
  },
  methods: {
    async queryLookupDestination(searchText) {
      // We only need to search when there is no selectedDestination
      // If we do, we might reset an already choosen selectedDestination!
      if (searchText != null && searchText != "") {
        this.isLoading = true
        let result = await this.$destinationRepository.lookupDestination(searchText)
        this.isLoading = false

        // Multiple searches might happen, even with debounce
        // We only use the result of this query if the request still matches
        // the current search text in the autocomplete input
        if (searchText == this.searchText) {
          this.destinations = []
          for (let destination of result.destinations) {
            this.destinations.push({
              key: destination.id + "-" + destination.destinationType,
              destinationId: destination.id,
              destinationType: destination.destinationType,
              name: destination.name,
            })
          }

          // we add the current entries at the end of the list, or the already selected
          // entries would be lost. For whatever reason, you can't have selected entries that
          // are not in the "items" list...
          for (let destination of result.selectedDestinations) {
            this.destinations.push(destination)
          }

          this.$refs.autocomplete.cachedItems = []

          console.log("RESULT", result.destinations)
        }
      }
    },
    shortenName(name) {
      let short = name.substr(0, 20)
      if (short != name) {
        short = short + "..."
      }
      return short
    },    
    resetSelectedDestinations() {
      this.destinations = [...this.value]
      this.selectedDestinations = [...this.value]
    },
    remove(item) {
      console.log("1", this.selectedDestinations)
      this.selectedDestinations = this.selectedDestinations.filter(sitem => sitem.key != item.key)
      console.log("2", this.selectedDestinations)

      this.updateValue(this.selectedDestinations)
    },
    updateValue(items) {
      this.$emit('input', items)
    },
  },
  watch: {
    errorMessages() {
      this.localErrorMessages = this.errorMessages
    },
    value() {
      this.resetSelectedDestinations()
    },
    searchText() {
      console.log("SEARCH", this.searchText)
      this.debounceQueryLookupDestination(this.searchText)
    }
  }
}
</script>