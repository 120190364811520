<template>
  <iframe :src="url"></iframe>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: "DestinationCountryInformation",
  props: {
    countryIso2: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapState({
      user: state => state.authStore.user,
      language: state => state.uiStore.language,
    }),
    url() {
      return 'https://global-monitoring.com/cdb/countries/' + this.countryIso2 + '/' + this.user.cdbAccessKey + '?lang=' + this.language
    }
  }
}
</script>