<template>
  <div class="pa-4" style="display: flex; flex-direction: column; flex:1">
    <!--------------------------- LOADING ---------------------->
    <div v-if="isLoading" style="flex: 1">
      <v-row style="flex: 1">
        <!--
        <v-col cols="2">
          <v-skeleton-loader
            class="mx-auto"
            type="article"
          />
          <v-skeleton-loader
            class="mx-auto"
            type="card"
          />
          <v-skeleton-loader
            class="mx-auto"
            type="article"
          />
        </v-col>
        -->
        <v-col>
          <v-skeleton-loader
            class="mx-auto"
            type="card"
          />
        </v-col>
        <v-col cols="2">
          <v-skeleton-loader
            class="mx-auto"
            type="article"
          />
          <v-skeleton-loader
            class="mx-auto"
            type="article"
          />
          <v-skeleton-loader
            class="mx-auto"
            type="article"
          />
          <v-skeleton-loader
            class="mx-auto"
            type="article"
          />
        </v-col>
      </v-row>      
    </div>

    <!--------------------------- RESULT ---------------------->

    <v-row  v-if="!isLoading" class="content-main">
      <!--
      <v-col cols="2" class="left-column">
        <h2 v-if="destinationDetail.destinationType == 'COUNTRY'" class="caption">{{ $t('main.COUNTRY') }}</h2>
        <h2 v-if="destinationDetail.destinationType == 'REGION'" class="caption">{{ $t('main.REGION') }}</h2>
        <h2 v-if="destinationDetail.destinationType == 'AIRPORT'" class="caption">{{ $t('main.AIRPORT') }}</h2>
        <h1 class="destination-name">
          {{ destinationDetail.name }}
          <span v-if="destinationDetail.destinationType == 'AIRPORT'">
            ({{ destinationDetail.iata }})
          </span>
        </h1>
        <h3 class="body-1 mb-4">{{ destinationDetail.dateStart|i18n_dateToGerman }} - {{ destinationDetail.dateEnd|i18n_dateToGerman }}</h3>

        <DestinationStatus
          :status="destinationDetail.statusDestination"
        />
        <DestinationDeviations
          v-if="destinationDetail.deviations && destinationDetail.deviations.length > 0"
          :deviations="destinationDetail.deviations"
        />

        <div class="spacer"></div>
        <p class="legal-warning caption grey--text text--darken-1">{{ $t('main.ENTRY_QUARANTINE_REGULATIONS_ASK_FOREIGN_MISSION_WARNING') }}</p>
      </v-col>
      -->

      <v-col class="center-column">
        <div style="display: flex; flex-direction: column; flex: 1">
        <!--
          <div style="display: flex; flex-direction: row;">
            <h2 v-if="destinationDetail.destinationType == 'COUNTRY'" class="caption">{{ $t('main.COUNTRY') }}</h2>
            <h2 v-if="destinationDetail.destinationType == 'REGION'" class="caption">{{ $t('main.REGION') }}</h2>
            <h2 v-if="destinationDetail.destinationType == 'AIRPORT'" class="caption">{{ $t('main.AIRPORT') }}</h2>
            <h1 class="destination-name">
              {{ destinationDetail.name }}
              <span v-if="destinationDetail.destinationType == 'AIRPORT'">
                ({{ destinationDetail.iata }})
              </span>
            </h1>
            <h3 class="body-1 mb-4">{{ destinationDetail.dateStart|i18n_dateToGerman }} - {{ destinationDetail.dateEnd|i18n_dateToGerman }}</h3>            
          </div>
        -->
          <DestinationTabbedContent
            :items="destinationDetail.items"
            :content="filteredContent"
            :events="destinationDetail.events"
            :countryRiskCategories="destinationDetail.countryRiskCategories"
            :countryIso2="destinationDetail.iso2"
            :destinationCountry="destinationDetail.destinationCountry"
            :transitCountry="destinationDetail.transitCountry"
            class="flex-grow-1"
          />
        </div>
      </v-col>

      <v-col cols="2" style="overflow: auto;">
        <v-expansion-panels
          v-model="actionPanel"
          class="action-panels"
        >
          <v-expansion-panel>
            <v-expansion-panel-header>
              {{ $t('main.CREATE_MYJOURNEY_PAGE_ACTION') }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <DestinationWidgetCreateMyTrip
                :departureType="destinationDetail.departureType"
                :departureId="destinationDetail.departureId"
                :destinationType="destinationDetail.destinationType"
                :destinationId="destinationDetail.destinationId"
                :destinationName="this.destinationDetail.name"
                :citizenshipCountryId="destinationDetail.citizenshipCountryId"
                :transitType="destinationDetail.transitType"
                :transitId="destinationDetail.transitId"
                :dateStart="destinationDetail.dateStart"
                :dateEnd="destinationDetail.dateEnd"
                :restrictionType="destinationDetail.restrictionType"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>
              {{ $t("main.FEEDBACK_ACTION") }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <DestinationWidgetFeedback
                :title="feedbackTitle"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel v-if="$hasPermission('a3m_core.DestinationManagerVisumPoint')">
            <v-expansion-panel-header>
              {{ $t('main.VISUMPOINT_ACTION') }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <DestinationWidgetVisumPoint
                :countryIso2="destinationDetail.iso2"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel v-if="$hasPermission('a3m_core.DestinationManagerDvkg')">
            <v-expansion-panel-header>
              {{ $t('main.DVKG_ACTION') }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <DestinationWidgetDvkg
                :countryIso2="destinationDetail.iso2"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel v-if="$hasPermission('a3m_core.DestinationManagerCIBTVisas')">
            <v-expansion-panel-header>
              {{ $t('main.CIBT_ACTION') }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <DestinationWidgetCIBT
                :countryIso2="destinationDetail.iso2"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel v-if="$hasPermission('a3m_core.DestinationManagerVisaGate')">
            <v-expansion-panel-header>
              {{ $t('main.VISAGATE_ACTION') }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              
              <DestinationWidgetVisaGate
                :countryIso2="destinationDetail.iso2"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

  </div>
</template>

<script>
import EntryRestrictionService from '@/services/EntryRestrictionService'
import DestinationTabbedContent from '@/components/destination/DestinationTabbedContent'
import DestinationWidgetCreateMyTrip from '@/components/destination/widgets/DestinationWidgetCreateMyTrip'
import DestinationWidgetFeedback from '@/components/destination/widgets/DestinationWidgetFeedback'
import DestinationWidgetVisumPoint from '@/components/destination/widgets/DestinationWidgetVisumPoint'
import DestinationWidgetDvkg from '@/components/destination/widgets/DestinationWidgetDvkg'
import DestinationWidgetCIBT from '@/components/destination/widgets/DestinationWidgetCIBT'
import DestinationWidgetVisaGate from '@/components/destination/widgets/DestinationWidgetVisaGate'

export default {
  name: "DestinationResultView",
  components: {
    DestinationTabbedContent,
    DestinationWidgetCreateMyTrip,
    DestinationWidgetFeedback,
    DestinationWidgetVisumPoint,
    DestinationWidgetDvkg,
    DestinationWidgetCIBT,
    DestinationWidgetVisaGate
  },
  data() {
    return {
      destinationDetail: null,
      isLoading: true,
      contentTab: 0,
      actionPanel: 0,
    }
  },
  mounted() {
    this.queryDestination(this.$route.params)
  },
  computed: {
    feedbackTitle() {
        if (this.destinationDetail.destinationType == 'COUNTRY') {
          return this.$t('main.COUNTRY') + ': ' + this.destinationDetail.name
        }
        if (this.destinationDetail.destinationType == 'REGION') {
          return this.$t('main.REGION') + ': ' + this.destinationDetail.name
        }
        if (this.destinationDetail.destinationType == 'AIRPORT') {
          return this.$t('main.AIRPORT') + ': ' + this.destinationDetail.name
        }

        return "-"
    },
    filteredContent() {
      if (!this.destinationDetail || !this.destinationDetail.content) {
        return []
      }

      let filter = []
      if (this.destinationDetail.restrictionType == 'TOURISM') {
        return this.destinationDetail.content
      }

      return EntryRestrictionService.filterGroupedDetailsForBusiness(
        this.destinationDetail.content
      )
    }
  },
  methods: {
    async queryDestination(params) {
      this.isLoading = true

      var transitType = null;
      var transitId = null;

      if (params.transitType != "UNDEFINED") {
        transitType = params.transitType;
        transitId = params.transitId;
      }

      let destinationDetail = await this.$destinationRepository.getDestinationDetail(
        params.departureType,
        params.departureId,
        params.destinationType,
        params.destinationId,
        params.citizenshipCountryId,
        params.restrictionType,
        params.dateStart,
        params.dateEnd,
        transitType,
        transitId,
      )
      this.destinationDetail = destinationDetail
      this.isLoading = false
    }
  },
  watch: {
    '$route.params': function () {
      this.queryDestination(this.$route.params)
    },
  }
}
</script>

<style scoped>
  .destination-name {
    line-height: 1.1;
  }

  .content-main {
    /*
    position: absolute;
    top: 12px;
    right: 12px;
    bottom: 12px;
    left: 12px;
    */
  }

  .left-column {
    display: flex;
    flex-direction: column;
  }

  .legal-warning {
    line-height: 14px;
  }

  .center-column {
    display: flex;
    flex-direction: column;    
  }

  .action-panels .v-expansion-panel-content {
    padding-top: 16px;
    background-color: #f7f7f7;
  }

</style>