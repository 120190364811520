<template>
  <v-autocomplete
    v-model="selectedDestination"
    :loading="isLoading"
    :items="destinations"
    @update:search-input="event => onUpdateSearchText(event)"
    :error-messages="localErrorMessages"
    item-text="name"
    :label="label"
    :no-data-text="$t('main.NO_RESULTS_FOUND')"
    hide-details
    no-filter
    clearable
    auto-select-first
    open-on-clear
    return-object
  >
    <template v-slot:item="data">
      <v-list-item-content>
        <v-list-item-title>{{ data.item.name }}</v-list-item-title>
        <v-list-item-subtitle>
          <span v-if="data.item.destinationType == 'COUNTRY'">{{ $t('main.COUNTRY') }}</span>
          <span v-if="data.item.destinationType == 'REGION'">{{ $t('main.REGION') }}</span>
          <span v-if="data.item.destinationType == 'AIRPORT'">{{ $t('main.AIRPORT') }}</span>

        </v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
import { debounce } from "debounce"
import moment from "moment"
import { mapState, mapMutations } from "vuex";

export default {
  name: "DestinationLookup",
  created() {
    /* must be in a per-component base, or we get problems with recreated instances*/
    this.debounceQueryLookupDestination = debounce((stext) => {
      return this.queryLookupDestination(stext)
    }, 500)
  },
  mounted() {
    this.selectedDestination = this.value
    this.localErrorMessages = this.errorMessages
  },
  props: {
    value: null,
    label: {
      type: String,
      default: ""
    },
    errorMessages: null
  },
  data() {
    return {
      selectedDestination: null,
      searchText: "",
      lookupResult: [],
      isLoading: false,
      localErrorMessages: null
    }
  },
  computed: {
    ...mapState({
      destinationLookupHistory: state => state.uiStore.destinationLookupHistory,
    }),
    destinationLookupHistoryByGroup() {
      if (this.destinationLookupHistory.length == 0) {
        return []
      }

      let entries = []
      let seenEntries = []
      let historyCount = 0

      let lastDay = null
      for (let lookupItem of this.destinationLookupHistory) {
        let seenId = lookupItem.destinationType + '#' + lookupItem.id

        if (historyCount > 20) {
          continue
        }

        if (seenEntries.indexOf(seenId) !== -1) {
          continue
        }

        if (lastDay != moment(lookupItem.lookupDate).format('YYYY-MM-DD')) {
          lastDay = moment(lookupItem.lookupDate).format('YYYY-MM-DD')

          if (moment(lookupItem.lookupDate).format('YYYY-MM-DD') == moment().format('YYYY-MM-DD')) {
            entries.push({ header: this.$t('main.TODAY') })
          } else {
            entries.push({ header: moment(lastDay).format('L') })
          }
        }

        entries.push(lookupItem)
        seenEntries.push(seenId)
        historyCount++
      }

      return entries
    },
    destinations() {
      if (this.searchText == "" || this.searchText == null) {
        if (this.destinationLookupHistoryByGroup.length == 0) {
          return []
        }

        return [
          { header: this.$t('main.SEARCH_HISTORY') },
          { divider: true },
          ...this.destinationLookupHistoryByGroup
        ]
      }
      return this.lookupResult
    }
  },
  methods: {
    ...mapMutations({
      addDestinationLookupHistory: 'uiStore/ADD_DESTINATION_LOOKUP_HISTORY'
    }),
    async queryLookupDestination(searchText) {
      // We only need to search when there is no selectedDestination
      // If we do, we might reset an already choosen selectedDestination!
      if (searchText != null && searchText != "" && this.selectedDestination == null) {
        this.isLoading = true
        let result = await this.$destinationRepository.lookupDestination(searchText)
        this.isLoading = false

        // Multiple searches might happen, even with debounce
        // We only use the result of this query if the request still matches
        // the current search text in the autocomplete input
        if (searchText == this.searchText) {
          this.lookupResult = result.destinations
        }
      }
    },

    onUpdateSearchText(searchText) {
      if (searchText != null && searchText != "") {
        this.searchText = searchText
        this.debounceQueryLookupDestination(searchText)
      }
    }
  },
  watch: {
    errorMessages() {
      this.localErrorMessages = this.errorMessages
    },
    selectedDestination() {
      this.localErrorMessages = null
      this.addDestinationLookupHistory(this.selectedDestination)
      this.searchText = ""

      this.$emit('input', this.selectedDestination)
    }
  }
}
</script>