<template>
  <v-alert
    v-if="isVisible"
    :type="type"
    :outlined="outlined"
  >
    <v-row align="center">
      <v-col class="grow">
        <slot />
      </v-col>
      <v-col class="shrink">
        <v-btn small color="primary" @click="accept">{{ actionText }}</v-btn>
      </v-col>
    </v-row>
  </v-alert>
</template>

<script>
export default {
  name: "AcceptableAlert",
  props: {
    acceptKey: {
      type: String,
      required: true      
    },
    type: {
      type: String,
      default: "warning"
    },
    actionText: {
      type: String,
      default: 'Ok'      
    },
    outlined: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isVisible: true
    }
  },
  mounted() {
    this.updateVisibility()
  },
  computed: {
    storageName() {
      return "accept-alert--" + this.acceptKey
    },
  },
  methods: {
    updateVisibility() {
      this.isVisible = !localStorage.getItem(this.storageName)
    },
    accept() {
      localStorage.setItem(this.storageName, true)
      this.updateVisibility()
    }
  }
}
</script>