<template>
  <v-simple-table class="gm-table">
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left text-no-wrap" colspan="2">
            {{ title }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="detail in details"
          :key="detail.detailType"
        >
          <td>{{ getName(detail.detailType) }}</td>
          <td>
            <span :style="{ color: getDetailColor(detail)}">
              {{ getDetailStatusValue(detail) }}
            </span>
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import EntryRestrictionService from '@/services/EntryRestrictionService';

export default {
  name: "DestinationContentListTable",
  props: {
    title: {
      type: String,
      required: true
    },
    details: {
      type: Array,
      required: true
    }
  },
  methods: {
    getName(detailTypeKey) {
      var detailType = EntryRestrictionService.getDetailTypeByKey(detailTypeKey)
      if (!detailType) {
        return "UNKNOWN: " + detailTypeKey
      }
      return detailType.name
    },
    getDetailColor(detail) {
      return EntryRestrictionService.getDetailColor(detail)
    },
    getDetailStatusValue(detail) {
      if (detail === null) {
        return ''
      }

      if (detail.value !== null && detail.value !== '') {
        return detail.value
      }

      if (EntryRestrictionService.isDetailStatusIsYes(detail)) {
        return this.$t('main.YES')
      }
      
      return this.$t('main.NO')
    }
  }
}
</script>