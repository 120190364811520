<template>
  <div class="detail-container">
    <!-- eslint-disable vue/no-v-html -->
    <div v-html="finalText" />
    <!-- eslint-enable -->
  </div>
</template>

<script>
export default {
  name: 'EntryRestrictionDetailBox',
  props: {
    text: {
      type: String,
      default: ''
    },
    status: {
      type: String,
      default: ''
    }
  },
  computed: {
    finalText() {
      const strippedStext = this.text.replace(/(<([^>]+)>)/gi, '')

      return this.text
    },
    isColorStatus() {
      return ['BLUE', 'GREEN', 'GRAY', 'YELLOW', 'RED'].includes(this.status)
    },
    glowingStatus() {
      let glowingStatus = ''
      switch (this.status) {
        case 'RED': glowingStatus = 'red'; break
        case 'YELLOW': glowingStatus = 'yellow'; break
        case 'BLUE': glowingStatus = 'green'; break
        case 'GREEN': glowingStatus = 'green'; break
        case 'GRAY': glowingStatus = 'gray'; break
      }
      return glowingStatus
    }
  }
}
</script>

<style scoped>

  .traffic-light-box {
    width: 30px;
    padding-top: 0.2rem;
  }

</style>
