<template>
  <div style="font-size: 90%">
    <div v-if="!done">
      <v-text-field
        v-model="content.title"
        :label="$t('main.FEEDBACK_TITLE')"
      />
      <v-text-field
        v-model="content.email"
        :label="$t('main.EMAIL_ADDRESS')"
        required
        :rules="emailRules"
      />
      {{ $t("main.PLEASE_SELECT_FEEDBACK_TYPE") }}:

      <v-checkbox v-model="content.wrongInformation.active" :label="$t('main.WRONG_INFORMATION')" />
      <v-textarea v-if="content.wrongInformation.active" v-model="content.wrongInformation.text" solo label="Text" />

      <v-checkbox v-model="content.incompleteInformation.active" :label="$t('main.INCOMPLETE_INFORMATION')" />
      <v-textarea v-if="content.incompleteInformation.active" v-model="content.incompleteInformation.text" solo label="Text" />

      <v-checkbox v-model="content.feedback.active" :label="$t('main.GENERIC_FEEDBACK')" />
      <v-textarea v-if="content.feedback.active" v-model="content.feedback.text" solo label="Text" />

      <p v-if="content.wrongInformation.active || content.incompleteInformation.active">
        {{ $t("main.FEEDBACK_WRONG_INFO_SORRY") }}
      </p>

      <v-btn
        :disabled="!valid"
        :loading="sending"
        @click="sendFeedback"
        color="primary"
        small
      >
        {{ $t("main.SEND_ACTION") }}
      </v-btn>
    </div>

    <div v-if="done">
      {{ $t("main.FEEDBACK_DONE_TEXT") }}

      <v-btn
        @click="reactivateFeedback"
        color="primary"
        small
        class="mt-2"
      >
        {{ $t("main.FEEDBACK_ACTION") }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "DestinationWidgetFeedback",
  props: {
    title: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      sending: false,
      done: false,
      emailRules: [
        v => !v || /.+@.+\..+/.test(v) || this.$t('main.EMAIL_ADDRESS_IS_INVALID'),
      ],
      content: {
        title: '',
        email: '',        
        wrongInformation: {
          active: false,
          text: ''
        },
        incompleteInformation: {
          active: false,
          text: ''
        },
        feedback: {
          active: false,
          text: ''
        }
      }
    }
  },
  mounted() {
    this.resetContent()
  },
  computed: {
    ...mapState({
      organisation: state => state.authStore.organisation,
      languages: state => state.uiStore.languages,
      language: state => state.uiStore.language
    }),
    valid() {
      return this.content.email != '' && (this.content.wrongInformation.active || this.content.incompleteInformation.active || this.content.feedback.active)
    }
  },
  methods: {
    resetContent() {
      this.content.title = this.title
      this.content.email = ""
      this.content.wrongInformation.text = ""
      this.content.incompleteInformation.text = ""
      this.content.feedback.text = ""
      this.content.wrongInformation.active = false
      this.content.incompleteInformation.active = false
      this.content.feedback.active = false
    },
    reactivateFeedback() {
      this.resetContent()
      this.done = false
    },
    sendFeedback() {
      let text = []

      if (this.content.email == '') {
        alert(this.$t('main.EMAIL_ADDRESS_IS_INVALID'))
        return
      }

      if (this.content.wrongInformation.active) {
        text.push("Falsche Informationen:\n" + this.content.wrongInformation.text)
      }
      if (this.content.incompleteInformation.active) {
        text.push("Unvollständige Informationen:\n" + this.content.incompleteInformation.text)
      }
      if (this.content.feedback.active) {
        text.push("Allgemeines Feedback:\n" + this.content.feedback.text)
      }

      let feedbackText = text.join("\n\n")

      feedbackText = "E-Mail: " + this.content.email + "\n" + feedbackText

      this.sending = true
      this.$destinationRepository.sendFeedback(this.content.title, feedbackText)
        .then(() => {
          this.sending = false
          this.done = true
        })
    }
  },
  watch: {
    title() {
      this.resetContent()
    }
  }
}
</script>
