<template>
  <div class="entry-restriction-groups">
    <v-row class="entry-restriction-groups-row">       
      <v-col
        v-for="(column, index) in byColumns"
        :key="index"
        md="4"
        sm="12"
        xs="12"
        class="entry-restriction-group"
      >
        <div
          v-for="group in column"
          :key="group.key"
        >
          <EntryRestrictionDetailList
            :open="openAll"
            :name="group.name"
            :details="group.details"
          />
        </div>

        <template
          v-if="disclaimer !== null && index == lastIndex"
        >
          <div class="spacer" />
          <div class="mx-4 mb-4 mt-8 caption grey--text text--darken-1">
            {{ disclaimer }}
          </div>
        </template>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import EntryRestrictionDetailList from './EntryRestrictionDetailList'

export default {
  name: 'EntryRestrictionDetailGroupList',
  components: {
    EntryRestrictionDetailList
  },
  props: {
    groups: {
      type: Array,
      required: true
    },
    breakPoints: {
      type: Array,
      default: () => []
    },
    openAll: {
      type: Boolean,
      default: false
    },
    disclaimer: {
      type: String,
      required: false,
      default: null
    },
  },
  computed: {
    lastIndex() {
      return this.byColumns.length - 1
    },
    byColumns() {
      let columns = []

      let current = []
      for (let group of this.groups) {
        if (this.breakPoints.indexOf(group.key) !== -1) {
          columns.push(current)
          current = []
        }
        current.push(group)

      }

      if (current.length > 0) {
        columns.push(current)
        current = []
      }

      return columns
    },
  }
}
</script>

<style scoped>
  .entry-restriction-groups {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .entry-restriction-groups .entry-restriction-group:last-child {
    margin-bottom: 0rem;
  }

  .entry-restriction-group {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .entry-restriction-groups-row {
    height: 100%;
  }

  .accept-disclaimer {
    flex-grow: 0;
  }

</style>
