<template>
  <div>
    <v-row style="margin-bottom: -2rem">
      <v-col cols="9">
        <v-row>
          <v-col cols="8">
            {{ $t('main.DESTINATION_ENTRY_COUNTRY') }}: {{ destinationCountry.name }}
          </v-col>
          <v-col cols="4">
            <span v-if="transitCountry">
              Transit: {{ transitCountry.name }}
            </span>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="3"></v-col>
    </v-row>

    <v-row>
      <v-col cols="8">
        <v-row>
          <v-col cols="4">
            <DestinationContentListTable
              :title="$t('main.PREVIEW_TITLE_ENTRY_DOCUMENTS')"
              :details="getDetailsByTypes(listTableEntryDocuments)"
            />
          </v-col>
          <v-col cols="4">
            <DestinationContentListTable
              :title="$t('main.PREVIEW_TITLE_ENTRTY_VISA')"
              :details="getDetailsByTypes(listTableEntryVisa)"
            />
          </v-col>
          <v-col cols="4">
            <DestinationContentListTable
              v-if="transitCountry"
              :title="$t('main.PREVIEW_TITLE_TRANSIT')"
              :details="getDetailsByTypes(listTableTansit)"
            />
          </v-col>
        </v-row>
        <v-row v-if="$hasPermission('a3m_core.DestinationManagerCDB')">
          <v-col cols="12">
            <DestinationCountryInfoOverviewTable
              :countryRiskCategories="countryRiskCategories"
            />
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="4">
        <DestinationContentListTable
          :title="$t('main.PREVIEW_TITLE_HEALTH')"
          :details="getDetailsByTypes(listTableHealth)"
        />
        <v-simple-table class="gm-table" style="width: 100%; margin-top: 20px;">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  {{ $t("main.GMEVENTS") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr style="background-color: white !important">
                <td class="pt-2">
                  <EventList
                    v-if="eventsLimited.length > 0"
                    :events="eventsLimited"
                  />
                  <div v-if="eventsLimited.length == 0">
                    {{ $t("main.NO_ENTRIES_FOUND") }}
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </div>
</template>


<script>
import EntryRestrictionService from "@/services/EntryRestrictionService";
import DestinationContentListTable from "@/components/destination/DestinationContentListTable";
import DestinationCountryInfoOverviewTable from "@/components/destination/DestinationCountryInfoOverviewTable";


import EventList from "@/components/event/EventList";

export default {
  name: "DestinationContentPreview",
  components: {
    DestinationContentListTable,
    DestinationCountryInfoOverviewTable,
    EventList,
  },
  props: {
    destinationCountry: Object,
    transitCountry: Object,
    content: Array,
    items: Array,
    countryRiskCategories: Array,
    events: Array,
  },
  data() {
    return {
      listTableEntryDocuments: [
        "ErBioPassportRequiredForEntry",
        "ErIDSufficientForEntry",
        "ErChildrenPassportSufficientForEntry",
      ],
      listTableEntryVisa: [
        "ErVisaRequiredForStay",
        "ErEntryPermitRequiredForStay",
        "ErVisaOnArrivalOrOnlineText",
      ],
      listTableTansit: [
        "ErTransitVisaRequired",
        "ErEntryPermitRequiredForTransit",
        "ErPassportRequiredForTransit"
      ],
      listTableHealth: [
        "ErCompulsoryHealthInsurance",
        "ErRequiredImmunizations",
        "ErRecommendedVaccinations",
        "ErSpecialExposure",
      ],
      compareTableEntryDetails: [
        {
          name: this.$t('main.ENTRY_COVID19_TABLE_ENTRY_ALLOWED'),
          // fixed status! Used because there is no real detail for this
          // The detail is only required to help the system understand the value
          vaccinated: { status: 'YES', detail: "EntryPossibleForPartiallyVaccinatedText" }, 

          partVaccinated: { detail: "EntryPossibleForPartiallyVaccinatedText" },
          recovered: { detail: "EntryPossibleForRecoveredText" },
          unvaccUnrecovered: { detail: "EntryPossibleForUnvaccAndUnrecText" },
        }, {
          name: this.$t('ui.QuarantineRequired'),
          vaccinated: { detail: "QuarantineUponEntryForVaccinatedText" },
          partVaccinated: { detail: "QuarantineUponEntryForPartiallyVaccinatedText", requireYes: "EntryPossibleForPartiallyVaccinatedText" },
          recovered: { detail: "QuarantineUponEntryForRecoveredText", requireYes: "EntryPossibleForRecoveredText" },
          unvaccUnrecovered: { detail: "QuarantineUponEntryForUnvaccAndUnrecText", requireYes: "EntryPossibleForUnvaccAndUnrecText" },
        }, {
          name: this.$t('ui.Covid19TestRequired'),
          vaccinated: { detail: "Covid19TestRequiredForEntryForVaccinatedText" },
          partVaccinated: { detail: "Covid19TestRequiredForEntryForPartiallyVaccinatedText", requireYes: "EntryPossibleForPartiallyVaccinatedText" },
          recovered: { detail: "Covid19TestRequiredForEntryForRecoveredText", requireYes: "EntryPossibleForRecoveredText" },
          unvaccUnrecovered: { detail: "Covid19TestRequiredForEntryForUnvaccAndUnrecText", requireYes: "EntryPossibleForUnvaccAndUnrecText" },
        },
      ]
    };
  },
  computed: {
    eventsLimited() {
      return this.events.slice(0, 5);
    },

    monkeypoxRestrictions() {
      return EntryRestrictionService.getDetailByTypeFromGroups(
          'MonkeypoxRestrictions',
          this.content
      )
    },

    monkeypoxRestrictionsStatusText() {
      if (this.monkeypoxRestrictions) {
        if (this.monkeypoxRestrictions['status'] == 'YES') {
          return this.$t('main.YES')
        } else if (this.monkeypoxRestrictions['status'] == 'NO') {
          return this.$t('main.NO')
        }
      }

      return this.$t('main.UNKNOWN')
    },

    monkeypoxRestrictionsText() {
      if (this.monkeypoxRestrictions) {
        return this.monkeypoxRestrictions.note        
      }

      return '<p>' + this.$t('detailtext.NoReliableInformationIsAvailable') + '</p>'
    }
  },
  methods: {
    getDetailsByTypes(detailTypes) {
      return EntryRestrictionService.getDetailsByTypes(
        detailTypes,
        this.items
      );
    },
    getCompareTableDetails(baseDetails) {
      const fields = [
        // target field, detailType
        ["vaccinated", "vaccinated"],
        ["partVaccinated", "partVaccinated"],
        ["recovered", "recovered"],
        ["unvaccUnrecovered", "unvaccUnrecovered"],
      ]

      let details = [];
      for (let compDetail of baseDetails) {
        let entry = { name: compDetail.name }

        for (let [targetField, sourceField] of fields) {
          if (compDetail[sourceField].status) {
            entry[targetField] = {
              "detailType": compDetail[sourceField].detail,
              "status": compDetail[sourceField].status,
              "value": ""
            }
            continue
          }         

          let detailType = null
          let requireYesDetailType = null
          if (compDetail[sourceField]) {
            detailType = compDetail[sourceField].detail

            if (compDetail[sourceField]['requireYes']) {
              requireYesDetailType = compDetail[sourceField]['requireYes']
            }
          }

          let detail = EntryRestrictionService.getDetailByTypeFromGroups(
            detailType,
            this.content
          )

          if (requireYesDetailType !== null) {
            let requireYesDetail = EntryRestrictionService.getDetailByTypeFromGroups(
              requireYesDetailType,
              this.content
            )

            if (requireYesDetail && requireYesDetail['status'] && requireYesDetail['status'] == 'NO') {
              detail = null
            }
          }

          entry[targetField] = detail
        }
        details.push(entry)
      }
      return details;
    },
  },
};
</script>
