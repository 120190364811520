<template>
  <div style="font-size: 90%">

    <v-dialog
      v-model="showLinkDialog"
      max-width="900"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{ $t("main.MYJOURNEY_PAGE") }}
        </v-card-title>

        <v-card-text>
          <v-alert type="success" class="mt-8">
            <input type="text"
              class="link-result-input"
              :value="myJourneyLink"
              id="myjourney-button-link"
            />
          </v-alert>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <v-btn color="primary" class="ml-2" @click="copyLinkAndOpen">{{ $t("main.CLIPBOARD_ACTION_AND_OPEN") }}</v-btn>
          <v-btn color="primary" class="ml-2" :href="'mailto:?body='+myJourneyLink" @click="closeLinkDialog">{{ $t("main.SEND_AS_EMAIL_ACTION") }}</v-btn>
          <v-btn color="secondary" class="ml-2" @click="closeLinkDialog">{{ $t("main.CLOSE_ACTION") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div>{{ dateStart|i18n_dateToGerman }} - {{ dateEnd|i18n_dateToGerman }}</div>
    <div v-if="restrictionType == 'TOURISM'">{{ $t("main.PRIVATE_TRAVEL") }}</div>
    <div v-if="restrictionType == 'BUSINESS'">{{ $t("main.BUSINESS_TRAVEL") }}</div>

    <v-select
      class="pt-6"
      :hint="$t('main.LANGUAGE')"
      v-model="content.language"
      :items="languageItems"
      dense
      persistent-hint
    />

    <div class="pt-6">{{ $t('main.MYTRIP_SELECT_DESTINATIONS') }}:</div>
    <DestinationLookupIdMultiSelect
      v-model="content.destinations"
    /> 

    <div class="pt-6">{{ $t('main.MYTRIP_SELECT_CITIZENSHIPS') }}:</div>
    <CountryLookupIdMultiSelect
      v-model="content.citizenshipCountryIds"
    />

    <div class="pt-6">{{ $t('main.EMAIL_UPDATES_LABEL') }}:</div>
    <v-text-field
      v-model="content.email"
      :label="$t('main.EMAIL_ADDRESS_LABEL')"
      :rules="emailRules"
      single-line
      dense
    />

    <!--
    <v-checkbox 
      :label="$t('main.MYTRIP_SHOW_ONLY_COVID19')"
      v-model="showCovidInfos"
      class="show-covid-info-checkbox"
      dense
      hide-details
      disabled
    />
    -->

    <!-- DISABLED
    <v-checkbox
      v-model="content.showEvents"
      :label="$t('main.MYTRIP_SHOW_ADDITIONAL_EVENTS')"
      dense
      hide-details
    />
    -->

    <v-textarea
      class="pt-6"
      v-model="content.internalNote"
      :label="$t('main.INTERNAL_NOTE')"
      outlined
      rows="2"
      dense
    />

    <v-btn
      :disabled="!isValid"
      class="mt-2"
      small
      color="primary"
      @click="createMyJourneyPage"
      :loading="creating"
    >
      {{ $t("main.CREATE_MYJOURNEY_PAGE_ACTION") }}
    </v-btn>
  </div>
</template>

<script>
import { mapState } from "vuex";
import CountryLookupIdMultiSelect from '@/components/destination/CountryLookupIdMultiSelect'
import DestinationLookupIdMultiSelect from '@/components/destination/DestinationLookupIdMultiSelect'


export default {
  name: "DestinationWidgetCreateMyTrip",
  components: {
    CountryLookupIdMultiSelect,
    DestinationLookupIdMultiSelect
  },  
  props: {
    departureType: {
      type: String,
      required: true
    },
    departureId: {
      type: [Number, String],
      required: true
    },    
    destinationType: {
      type: String,
      required: true
    },
    destinationId: {
      type: [Number, String],
      required: true
    },
    destinationName: {
      type: String,
      required: true
    },    
    transitType: {
      type: String,
      required: false
    },
    transitId: {
      type: [Number, String],
      required: false
    },    
    citizenshipCountryId: {
      type: [Number, String],
      required: true
    },
    dateStart: { // ISO FORMAT
      type: String,
      required: true
    },
    dateEnd: { // ISO FORMAT
      type: String,
      required: true
    },
    restrictionType: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      creating: false,
      showLinkDialog: false,
      myJourneyLink: null,
      showCovidInfos: true, // always true, required for checkbox
      content: {
        language: 'de',
        email: "",
        internalNote: "",
        showEvents: false,
        citizenshipCountryIds: [],
        destinations: []
      },
      emailRules: [
        v => !v || /.+@.+\..+/.test(v) || this.$t('main.EMAIL_ADDRESS_IS_INVALID'),
      ],
    }
  },
  mounted() {
    this.resetContent()
  },
  computed: {
    ...mapState({
      organisation: state => state.authStore.organisation,
      languages: state => state.uiStore.languagesMyTrip,
      language: state => state.uiStore.language
    }),
    isValid() {
      if (this.content.citizenshipCountryIds.length > 4) {
        return false
      }
      if (this.content.destinations.length == 0 || this.content.destinations.length > 10) {
        return false
      }
      return true
    },
    languageItems() {
      let languageItems = []

      for (const lang in this.languages) {
        languageItems.push({
          "text": this.$t('main.LANG_'+lang.toUpperCase()),
          "value": lang
        })
      }

      console.log(languageItems)

      return languageItems
    }
  },
  methods: {
    resetContent() {
      this.content.language = this.language
      this.content.email = ""
      this.content.showEvents = false
      this.content.citizenshipCountryIds = [this.citizenshipCountryId]
      this.content.destinations = [{
        "key": this.destinationId + "-" + this.destinationType,
        "destinationId": this.destinationId,
        "destinationType": this.destinationType,
        "name": this.destinationName
      }]
    },
    createMyJourneyPage() {
      this.creating = true

      let destinations = this.content.destinations.map(destination => {
        return {
          destinationId: destination.destinationId,
          destinationType: destination.destinationType,
        }
      })

      this.$myJourneyRepository.createMyJourney(
        this.departureType,
        this.departureId,

        destinations,

        this.content.citizenshipCountryIds,

        this.dateStart,
        this.dateEnd,
        this.restrictionType,
        this.content.language,
        this.content.email,
        this.content.showEvents,
        this.content.internalNote.substring(0, 1000),

        this.transitType,
        this.transitId        
      ).then((result) => {
        this.creating = false
        this.myJourneyLink = result.myjourney.url
        this.resetContent()
        this.openLinkDialog()
      }).catch((error) => {
        this.resetContent()
        this.creating = false
      })
    },
    copyLink() {
      var content = document.getElementById('myjourney-button-link')
      content.select()
      document.execCommand('copy')
    },
    openLink() {
      window.open(this.myJourneyLink)
    },
    copyLinkAndOpen() {
      this.copyLink()
      this.openLink()
      this.closeLinkDialog()
    },
    openLinkDialog() {
      this.showLinkDialog = true
    },
    closeLinkDialog() {
      this.showLinkDialog = false
    }
  },
  watch: {
    dateStart() {
      this.resetContent()
    },
    dateEnd() {
      this.resetContent()
    },
    destinationId() {
      this.resetContent()
    },
    destinationType() {
      this.resetContent()
    },
    citizenshipCountryId() {
      this.resetContent()
    },    
  }
}
</script>

<style scoped>
.link-result-input {
  background-color: white;
  width: 100%;
  min-width: 600px;
  padding: 6px;
}
</style>

<style>
.show-covid-info-checkbox .v-label {
  color: rgba(0, 0, 0, 0.6)!important;
}
</style>