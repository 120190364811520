<template>
  <v-autocomplete
    :value="selectedCountries"
    @input="updateValue($event)"
    :loading="isLoading"
    :items="countries"
    :error-messages="localErrorMessages"
    item-text="nameShort"
    item-value="id"
    :label="label"
    :no-data-text="$t('main.NO_RESULTS_FOUND')"
    hide-details
    auto-select-first
    open-on-clear
    return-object
    multiple
    chips
    dense
  >
    <template v-slot:selection="data">
      <v-chip
        v-bind="data.attrs"
        :input-value="data.selected"
        close
        @click="data.select"
        @click:close="remove(data.item)"
      >
        {{ shortenName(data.item.nameShort) }}
      </v-chip>
    </template>    
  </v-autocomplete>
</template>

<script>
import { debounce } from "debounce"
import moment from "moment"
import { mapState, mapActions } from "vuex";

export default {
  name: "CountryLookupIdMultiSelect",
  created() {
    /* must be in a per-component base, or we get problems with recreated instances*/
    this.debounceQueryLookupCountry = debounce((stext) => {
      return this.queryLookupCountry(stext)
    }, 500)
  },
  mounted() {
    this.isLoading = true
    this.countriesFetchAll()
      .then(() => {
        this.isLoading = false;
        this.resetSelectedCountries()
      })

    this.localErrorMessages = this.errorMessages
  },
  props: {
    value: [],
    label: {
      type: String,
      default: ""
    },
    errorMessages: null
  },
  data() {
    return {
      selectedCountries: [],
      searchText: "",
      lookupResult: [],
      isLoading: false,
      localErrorMessages: null
    }
  },
  computed: {
    ...mapState({
      countries: state => state.countriesStore.entries,
    }),
  },
  methods: {
    ...mapActions({
      countriesFetchAll: "countriesStore/fetchAll"
    }),
    resetSelectedCountries() {
      this.selectedCountries = []
      for (let val of this.value) {
        this.selectedCountries.push({
          id: val,
          text: ""
        })
      }
    },
    shortenName(name) {
      let short = name.substr(0, 20)
      if (short != name) {
        short = short + "..."
      }
      return short
    },
    remove(item) {
      this.selectedCountries = this.selectedCountries.filter(sitem => sitem.id != item.id)
      this.updateValue(this.selectedCountries)
    },
    updateValue(items) {
      let ids = items.map(item => item.id)
      this.$emit('input', ids)
    },
  },
  watch: {
    errorMessages() {
      this.localErrorMessages = this.errorMessages
    },
    value() {
      this.resetSelectedCountries()
    }
  }
}
</script>