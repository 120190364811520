<template>
  <div class="riskLevelBox" :class="cssClasses">
    <span v-if="riskLevel == 1">{{ $t('risklevel.level1') }}</span>
    <span v-else-if="riskLevel == 2">{{ $t('risklevel.level2') }}</span>
    <span v-else-if="riskLevel == 3">{{ $t('risklevel.level3') }}</span>
    <span v-else-if="riskLevel == 4">{{ $t('risklevel.level4') }}</span>
    <span v-else-if="riskLevel == 5">{{ $t('risklevel.level5') }}</span>
    <span v-else>{{ $t('risklevel.unknown') }}</span>
  </div>
</template>

<script>
export default {
  name: "RiskLevelBox",
  props: {
    riskLevel: {
      type: Number,
      required: true
    }
  },
  computed: {
    cssClasses() {
      return [
        'risk-level-color--' + this.riskLevel
      ]
    }
  }
}
</script>

<style scoped>
  .riskLevelBox {
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>