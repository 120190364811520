<template>
  <div>
    <h4 class="title mt-0">
      <span class="visumpoint-red">visumPOINT</span>
      {{ $t('main.VISA_REQUIREMENTS') }}
    </h4>
    <div v-if="countriesIsLoading">
      <v-progress-circular indeterminate color="primary" />
    </div>

    <div v-if="!countriesIsLoading && !countriesIsDone">
      {{ $t('main.ERROR') }}
    </div>

    <div v-if="countriesIsDone">
      {{ $t("main.SELECT_TRAVELER_NATIONALITY") }}:
      <v-autocomplete
        v-model="nationality"
        :items="countries"
        item-text="nameShort"
        item-value="iso2"
        :hint="$t('main.SELECT_TRAVELER_NATIONALITY')"
      />
      <v-select v-model="purpose" :items="purposes" :hint="$t('main.SELECT_TRAVEL_REASON')" />
      <v-btn class="mt-4" color="primary" small @click="openVisumPoint">{{ $t('main.SHOW_VISA_ACTION') }}</v-btn>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "DestinationWidgetVisumPoint",
  props: {
    countryIso2: String
  },
  data() {
    return {
      nationality: "DE",
      purpose: "1",
      purposes: [
        { value: "1", text: this.$i18n.t('main.TRAVEL_REASON_TOURIST') },
        { value: "2", text: this.$i18n.t('main.TRAVEL_REASON_BUSINESS') },
        { value: "3", text: this.$i18n.t('main.TRAVEL_REASON_OTHERS') }
      ],

      cdbVisumPointEndPoint: "https://www.visumpoint.de/visapflichtw.php",
      cdbVisumPointEndPointPasskey: "WGlQTXB2VzQ3bW1ad1diNVdqMTJ4NFVRMTE3R2pMOHVUTTRGK3VtZ05jZy9iM3NHNmxwTUh5MVBoYkNieDVYbEN6Zm1Yc0kxLzJWaDVFcGxrMkppVXc9PTo6CgN7r62W8CASsP9uxXeTDw=="
    };
  },
  mounted() {
    this.countriesFetchAll();
  },
  computed: {
    ...mapState({
      countries: state => state.countriesStore.entries,
      countriesIsDone: state => state.countriesStore.isDone,
      countriesIsLoading: state => state.countriesStore.isLoading,
      language: state => state.uiStore.language
    })
  },
  methods: {
    ...mapActions({
      countriesFetchAll: "countriesStore/fetchAll"
    }),
    openVisumPoint() {
      let url = `${this.cdbVisumPointEndPoint}?passkey=${this.cdbVisumPointEndPointPasskey}&lang=${this.language}&land_pflicht=${this.countryIso2}&art_pflicht=${this.purpose}&national_pflicht=${this.nationality}`;
      window.open(url, '_blank')
    }
  },
  watch: {
    countryIso2() {
      this.countriesFetchAll();
    }
  }
};
</script>

<style scoped>
h4 {
  margin-top: 2rem;
}

.visumpoint-red {
  color: #B6362B;
}
</style>