<template>
  <div class="main">
    <div class="header">
      <v-row>
        <v-col class="pt-6 px-8">
          <DestinationSearchBar
            @search="search"
          />
        </v-col>
      </v-row>
    </div>

    <div class="content">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import DestinationSearchBar from '@/components/destination/DestinationSearchBar'

export default {
  name: "DestinationView",
  components: {
    DestinationSearchBar
  },
  methods: {
    search(searchData) {
      this.$router.push({
        name: "destination-result",
        params: searchData
      });
    }
  }
}
</script>

<style scoped>
  .main {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .header {
    flex-shrink: 0;
    height: 80px;
  }

  .content {
    display: flex;
    flex-direction: row;
    position: relative;
    background-color: #ebeaea;
    flex: 1 1 auto;
    overflow-y: auto;
    min-height: 0px;
  }
</style>