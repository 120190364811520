<template>
  <v-simple-table class="gm-table overview-table">
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">
            {{ $t('main.COUNTRY_INFORMATION_TABLE') }}
          </th>
        </tr>
      </thead>
      <tbody class="mb-4">
        <tr>
          <td class="pa-0">
            <v-row no-gutters>
              <v-col
                v-for="riskCategoryCode in riskCategories"
                :key="riskCategoryCode"
                cols="4"
                class="pa-0"
              >
                <div class="riskCategoryBox">
                  <div class="riskCategoryBoxText">{{ getRiskCategoryNameByCode(riskCategoryCode) }}</div>
                  <RiskLevelBox
                    :riskLevel="getRiskCategoryLevelByCode(riskCategoryCode)"
                    class="riskCategoryBoxLevel"
                  />
                </div>
              </v-col>
            </v-row>
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>
 
<script>
import RiskLevelBox from '@/components/countryinformation/RiskLevelBox'

export default {
  name: "DestinationCountryInfoOverviewTable",
  components: {
    RiskLevelBox
  },
  props: {
    countryRiskCategories: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      riskCategories: [
        'entryandexitrequirements',
        'health',
        'naturalhazard',

        'transport',
        'infectiousdisease',
        'security',

        'strike',
        'vaccination',
        'special',

        'essentialservice',
        'hygiene',
        'businesssecurity',
      ]
    }
  },
  methods: {
    getRiskCategoryNameByCode(riskCategoryCode) {
      for (let riskCategory of this.countryRiskCategories) {
        if (riskCategory.riskCategory.code == riskCategoryCode) {
          return riskCategory.riskCategory.title
        }
      }
      return "?"
    },
    
    getRiskCategoryLevelByCode(riskCategoryCode) {
      for (let riskCategory of this.countryRiskCategories) {
        if (riskCategory.riskCategory.code == riskCategoryCode) {
          return riskCategory.level
        }
      }
      return 0
    },
  }
}
</script>

<style scoped>
.riskCategoryBox {
  margin-right: 2px;
  margin-bottom: 2px;
  background-color: white;
  padding: 8px;
  display: flex;
  align-items: center;
}

.riskCategoryBoxText {
  flex: 1;
}

.riskCategoryBoxLevel {
  width: 100px;
  height: 30px;
}

.overview-table {
  width: 100%;
  background-color: #f7f7f7!important;
}
 
</style>