<template>
  <v-row no-gutters>
    <v-col cols="3" class="event-display-left">
      <div class="event-display-left-scroll">
        <DateList
          v-if="dateListItems.length"
          :items="dateListItems"
          :allow-click="true"
          :timeAtCategory="true"
          selectable
          @select="openEventPage"
        />
        <div v-if="!dateListItems.length">
          {{ $t('main.NO_ENTRIES_FOUND') }}
        </div>
      </div>
    </v-col>
    <v-col class="pa-0">
      <iframe
        v-if="event"
        :src="event.staticPageUrl"
        class="event-content"
        frameborder="0"
      />

      <div
        v-if="!event"
        class="empty-icon"
      >
        <v-icon size="300" color="grey lighten-3">event</v-icon>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import EventService from '@/services/EventService';
import DateList from '@/components/ui/DateList';

export default {
  name: "EventDisplay",
  components: {
    DateList
  },
  props: {
    events: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      event: null
    }
  },
  computed: {
    dateListItems() {
      let newsEvents = []
      for (let event of this.events) {
        let newsEvent = {}

        let icon = EventService.getIconFileNameByCategorty(event.category.code, event.level)

        newsEvent.id = event.id
        newsEvent.text = event.title
        newsEvent.createdAt = event.significantUpdateAt
        newsEvent.category = event.category.name

        newsEvent.categoryIcon = icon

        newsEvent.isNew = event.countUpdates == 0
        newsEvent.isUpdated = event.countUpdates > 0

        newsEvent.isUnread = event.isUnread

        newsEvent.staticPageUrl = event.staticPageUrl

        newsEvents.push(newsEvent)
      }

      return newsEvents
    }
  },
  methods: {
    openEventPage(event) {
      this.event = event
    },
  }
}
</script>

<style scoped>
  .event-display-left {
    position: relative;
    background-color: white;
  }

  .event-display-left-scroll {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    padding: 24px;
  }

  .event-content {
    width: 100%;
    height: 100%;
  }

  .empty-icon {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>