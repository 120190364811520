<template>
  <div>
    <img src="@/assets/logo_cibt.png" class="logo" />
    <v-btn class="mt-4" color="primary" small href="https://cibtvisas.de/visas" target="_blank">{{ $t('main.VISA_REQUIREMENTS') }}</v-btn>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "DestinationWidgetCIBT",
  props: {
    countryIso2: String
  },
  data() {
    return {
       defaultEndPoint: "https://dvkg.de/de/external/a3mobile/countries/information/new"
    };
  },
  computed: {
    ...mapState({
      organisation: state => state.authStore.organisation,
    }),
    endPoint() {
      if (this.organisation.dvkgUrl !== '') {
        return this.organisation.dvkgUrl
      }
      return this.defaultEndPoint
    }
  },
  methods: {
    openDvkg() {
      let url = `${this.endPoint}?country=${this.countryIso2}`;
      window.open(url, '_blank')
    }
  }
};
</script>

<style scoped>
.main-row {
  margin-top: 2rem;
}

.logo {
  width: 100%;
}

</style>